import React, { useState } from "react";
import "./App.css";
import "./index.css";

function App() {
  const [activeModal, setActiveModal] = useState(null);

  const openModal = (modalName) => {
    setActiveModal(modalName);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  return (
    <div className="relative w-full h-screen overflow-hidden">
      {/* Full-Screen Background Image */}
      <div
        className={`absolute inset-0 bg-cover bg-center transition-all duration-300 ${
          activeModal ? "blur-sm" : ""
        }`}
        style={{
          backgroundImage: `url('/moustache.jpg')`,
        }}
      ></div>

      {/* Glowing Buttons */}
      <div
        className={`relative z-10 flex justify-center items-start pt-6 space-x-6 ${
          activeModal ? "blur-sm" : ""
        }`}
      >
        <button
          className="px-6 py-2 text-white font-semibold bg-blue-600 rounded-full shadow-md hover:shadow-blue-400 transition-all duration-300 hover:scale-105 glow"
          onClick={() => openModal("tokenomics")}
        >
          Tokenomics
        </button>
        <button
          className="px-6 py-2 text-white font-semibold bg-green-600 rounded-full shadow-md hover:shadow-green-400 transition-all duration-300 hover:scale-105 glow"
          onClick={() => openModal("about")}
        >
          About
        </button>
        <a href="https://x.com/moustache9000">
          <button className="px-6 py-2 text-white font-semibold bg-orange-600 rounded-full shadow-md hover:shadow-orange-400 transition-all duration-300 hover:scale-105 glow">
            X
          </button>
        </a>
      </div>

      {/* Modal */}
      {activeModal && (
        <div className="absolute inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-11/12 max-w-lg text-center">
            <h2 className="text-xl font-bold mb-4">
              {activeModal === "tokenomics" && "Tokenomics"}
              {activeModal === "about" && "About"}
              {activeModal === "xlink" && "X Link"}
            </h2>
            <p className="text-gray-700 mb-6">
              {activeModal === "tokenomics" &&
                "CA 7FuThbsCZWm3qPT3a3eThKFdrDDX5BBCiPkxNxQmpump"}
              {activeModal === "about" && (
                <div>
                  "Meticulously groomed saiyan moustache with 9000 rizz level
                  <br />
                  The universal daddy and rizz of saiyan"
                </div>
              )}
              {activeModal === "xlink" &&
                "Links and resources for X will appear here."}
            </p>
            <button
              className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
